<template>
    <v-container class="my-5">

        <template>
            <div >
                <v-data-table
					:loading="!isDownloaded"
                    :headers="headers"
                    :items="tasks"
                    item-key="id"
                    class="elevation-2"
                    :hide-default-footer="true"
                    :disable-pagination="true"
                    :sort-desc="false"
                >

                    <template v-slot:item.date="{ item }">
                        {{ item.date.substr(0, 10) }}
                    </template>

                    <template v-slot:item.clientId="{ item }">
                        {{ item.clientId ? item.clientId.name : '' }}
                    </template>

                    <template v-slot:item.dateCp="{ item }">
                        {{ item.dateCp.substr(0, 10) }}
                        {{correctDate(item.dateCp)}} 

                    </template>

                    <template v-slot:item.copyRate="{ item }">
                        {{ item.copyRate.toFixed(2) }}
                    </template>

                    <template v-slot:item.fullRate="{ item }">
                        {{ item.fullRate.toFixed(2) }}
                    </template>

                    <template v-slot:item.status="{ item }">
                        <v-chip :color="color(item.status)" text-color="white">{{ item.status }}</v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
						<div class="d-flex align-center" >
							<v-btn
								color="green"
								class="mt-1 mb-1 mr-2 white--text"
								@click.stop="takeTask(item._id)"
								:loading="isLoadedTake"
							>
								Przyjmij zadanie
							</v-btn>
							<ShowDescription :description="item.description"/>
						</div>
                    </template>

                </v-data-table>

                <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                >
                    {{ text }}

                    <template>

                        <v-btn
                            color="blue"
                            text
                            @click="snackbar = false"
                        >
                            Zamknij
                        </v-btn>

                    </template>

                </v-snackbar>

            </div>
        </template>

    </v-container>
</template>

<script>
import io from 'socket.io-client';
import { chipColors } from '@/mixins/ChipColors'
import ShowDescription from '@/components/molecules/ShowDescription.vue';

const token = localStorage.getItem('user');

export default {
    data(){
        return{
			isLoadedTake: false,
            tasks: [],
            clients: [],
			socket: io(this.url),
			isDownloaded: false,
            headers: [
                { text: 'Zadanie', align: 'left', sortable: true, value: 'name', },
                { text: 'Klient', value: 'clientId', sortable: true  },
                { text: 'Deadline', value: 'dateCp', sortable: true },
                { text: 'Stawka', value: 'copyRate', sortable: true  },
                { text: 'Znaki', value: 'characters', sortable: true  },
                { text: 'Akcje', value: 'actions', sortable: false },
            ],
            snackbar: false,
            text: 'Zadanie przyjęte',
            timeout: 2000,
        }
	},
	computed: {
		isLoaded(){
			return this.tasks.length == 0
		}
	},
    mixins: [chipColors],
    methods:{
        getPosts(){
            this.$axios.get(`${this.url}api/tasks/eter/${this.eter}`, { headers: { 'x-access-token': token }})
            .then(resp => {
				this.tasks = resp.data.filter(task => task.status !== 'Szkic');
				this.isDownloaded = true;
            });
        },
        correctDate(date){
			const newDate = new Date(date);
			return `${newDate.getHours()}:${newDate.getMinutes() < 9 ?   '0' + newDate.getMinutes() : newDate.getMinutes()}`
		},
        takeTask(id){
			this.isLoadedTake = true;
            this.$axios.put(`${this.url}api/tasks/pick`, {
                copywriterId: localStorage.getItem('id'),
                id: id,
				status: 'Przyjęte'
			}, { headers: { 'x-access-token': token }})
			.then(()=> {
				this.isLoadedTake = false;
			})
			.catch(err => {
				console.log(err);
			})
        }
	},
	components: {
		ShowDescription,
	},
    created(){
        this.getPosts();

        this.socket.on('newTaskAdded', () => {
            this.getPosts()
        });

        this.socket.on('editedTask', () => {
            this.getPosts()
        });

        this.socket.on('taskDeleted', () => {
            this.getPosts()
        });
    },
}
</script>
